<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                      v-model="credentials.email"
                      prepend-icon="mdi-account"
                      name="login"
                      label="Login"
                      type="text"
                  ></v-text-field>
                  <v-text-field
                      v-model="credentials.password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Password"
                      type="password"
                  ></v-text-field>
                </v-form>
                <v-alert
                    type="error"
                    v-if="error"
                >
                  {{ error }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="submit">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import axios from "axios"
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      credentials: {
        email: '',
        password: ''
      }
    }
  },
  computed: mapGetters('auth', ['error']),
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    async submit() {
      await this.login(this.credentials);
    }
  }
}
;
</script>

<style></style>
